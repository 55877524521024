import React from "react"
import Img from "gatsby-image"

// import { StyleSheet, css } from 'aphrodite';
import '../global.css';

import Layout from "../components/layout"
import Image from '../components/image'
import SEO from "../components/seo"
import Grid from '@material-ui/core/Grid';

const approaches = [
  {
    title: 'Interplay',
    content: 'Spectrum Management Consulting’s unique offering is grounded in our ability to understand the strategic interplay of technologies, business, regulations and competitive forces. This rare perspective, bringing together many elements of a fast-changing industry and understanding how they impact one another, is critical to addressing the most fundamental questions posed by strategic leaders and decision makers.'
  },
  {
    title: 'Lifecycle',
    content: 'Our holistic experience, having worked in all phases of the industry lifecycle over multiple projects and verticals, provides an incomparable capability to help our clients with their own challenges.  This includes idealization, planning, design (conceptual, preliminary, detail), implementation, operations, optimization/improvement, transition/upgrade implementation, and even sunsetting and decommissioning.'
  },
  {
    title: 'Value Chain',
    content: 'Our ability to articulate and understand the complete value chain and to pinpoint where clients should focus attention and resources is critical to creating value for our clients.  SMC has deep expertise in the service provider’s value chain.  This includes, but is not limited to, spectrum, spectrum controllers, hardware OEM, connectivity - radio access networks/transport/core (including technologies & standards), network operations, service enablement, systems integration, network services, devices, management services for network (user/ device mgt, configuration, security, policy, etc) and applications and platforms.'
  }
]

const services = [
  'Management-for-hire services (CTO/CSO/Engineering)',
  'Consulting solutions including strategic & business/financial planning & modeling', 
  'Technical & operational strategy',
  "Development and implementation support",
  "Due diligence and transaction, financial and technical modeling",  
  "Change & performance",
  "Cost improvement",
  "Engineering services",
  "Regulatory evaluations ",
  "Other technical and strategic business services."
]

const Approach = ({ title, content }) => {
  return (
    <div className="row">
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={4}>
          <h3>{title}</h3>
        </Grid>
        <Grid item xs={12} sm={8}>
          {content}
        </Grid>
    </Grid>
  </div>
    
  )
}

const About = () => (
  <Layout>
    <SEO title="About Us" />

    <Image></Image>
    <div className="pageHeader">
      <h1>The Strategic Interplay of Technologies, Business, Regulations and Competitive Forces</h1>
    </div>
    {/* <div style={{ display: "flex", justifyContent: "center", marginBottom: "2em"}}>
      <a className="navbar-get-in-touch" style={{ fontSize: "24px", padding: "0.8em 3em"}} href="mailto: info@spectrumplay.com">
        Get in Touch
      </a>
    </div> */}

    <div className="section">
      <h1>About Us</h1>
      <p>Spectrum Management Consulting (SMC) is a boutique independent consulting firm focused on telecommunications, media, technology, and adjacent sectors including end user devices, digital infrastructure, satellite, the cloud and the internet.  We apply extensive industry knowledge, consulting experience, and rigorous analysis to address challenges and opportunities across networks and infrastructure, regulatory, radio spectrum, economic and business planning, and technology domains. </p>

      <p>The company was founded in 2005 based on our deep knowledge and breadth of expertise. Key principals have over 30 years of experience with cross-disciplinary knowledge of and insight into business strategy, technology operations and regulatory matters, and, most importantly, their interrelationships.</p>

      <p>Based outside of Washington DC in the technology corridor of Northern Virginia, we are proven and experienced in delivering to diverse needs and approaches globally.  Our client base is a diverse who’s who in telecommunications, including spectrum owners, service providers, vendors, professional investors, digital infrastructure providers and legal firms.</p>
    </div>

    <div className="section">
      <h1>Our Approach</h1>
      <div className="approachHeader">
        {approaches.map((a, i) => (
          <Approach title={a.title} content={a.content} key={i}/>
        ))}
        </div>
    </div>
    
    <div className="section">
      <h1>Services Offered</h1>
      <p>SMC supports our clients’ needs with a range of advisory services: </p>
      <ul>
        {services.map((s, i) => (
          <li key={i}>{s}</li>
        ))}
      </ul>
    <p>For spectrum owners lacking in-house operational expertise, we also offer turnkey outsourced wireless operator lifecycle services including sales of services, operation, and management of networks to fulfill construction buildout requirements. </p>
    </div>

  </Layout>
)


export default About;
